<template>
  <div :class="getFlowClasses">
    <div class="flow-card__header">
      <div class="flow-title">
        {{ flowInfo.name }}
      </div>
      <div class="flow-id">
        (ID: {{ flowInfo.id }})
      </div>
      <div class="flow-record flow-text">
        {{ $t('modelFlow.createdInfo', { name: flowInfo.createdBy, time: timeToDateTime(flowInfo.createdAt) }) }}
      </div>
    </div>
    <div
      ref="card"
      class="flow-card__content"
    >
      <div class="flow-card__list">
        <div class="flow-info-wrapper">
          <div class="flow-label">
            {{ $t('modelFlow.updateMode') }}：
          </div>
          <div class="flow-text">
            {{ $t(`modelFlow.triggerTypes.${flowInfo.triggerType.toLowerCase()}`) }}
          </div>
        </div>
        <flow-update-status
          :flow-info="flowInfo"
          :is-flow-updating="isFlowUpdating"
          @disable="isFlowUpdating = $event"
        />
        <div class="flow-function-wrapper">
          <span class="flow-function">
            <a
              class="link"
              @click="goToFlowDetailPage(flowInfo.id)"
            >{{ $t('modelFlow.settingManagement') }}</a>
          </span>
          <flow-action-dropdown
            :is-flow-updating="isFlowUpdating"
            class="flow-function"
            @action="$emit('action', $event)"
          />
        </div>
      </div>
      <div class="flow-card__list">
        <div
          class="flow-output"
          ref="flowOutput"
        >
          <div class="flow-label">
            {{ $t('modelFlow.outputResult') }}：
          </div>
          <div
            class="flow-output__result"
            v-if="flowInfo.targetDataSource && flowInfo.targetDataSource.length"
          >
            <div
              class="flow-text-wrapper horizontal"
              v-for="(source, index) in flowInfo.targetDataSource"
              :key="`${source}-${index}`"
              ref="items"
            >
              <svg-icon
                icon-class="data-source"
                class="icon"
              />
              <div class="flow-text">
                <span
                  class="flow-text--underline"
                  @click="goToDataSourcePage(index)"
                >
                  {{ source }}
                </span>
              </div>
              <svg-icon
                icon-class="table"
                class="icon"
              />
              <div class="flow-text">
                {{ flowInfo.targetDataFrameName[index] }}
              </div>
            </div>
          </div>
          <div v-else>
            -
          </div>
        </div>
        <div
          v-if="occurOverflow"
          class="flow-text tooltip-container"
        >
          {{ `+${flowInfo.targetDataSource.length - overflowPosition}` }}
          <div class="tooltip">
            <div class="vertical">
              {{ $t('modelFlow.outputResult') }}
            </div>
            <div
              class="flow-text-wrapper vertical"
              v-for="(source, index) in flowInfo.targetDataSource.slice(overflowPosition)"
              :key="`${source}-${index}`"
            >
              <svg-icon
                icon-class="data-source"
                class="icon"
              />
              <div class="flow-text">
                <span
                  class="flow-text--underline"
                  @click="goToDataSourcePage(overflowPosition + index)"
                >
                  {{ source }}
                </span>
              </div>
              <svg-icon
                icon-class="table"
                class="icon"
              />
              <div class="flow-text">
                {{ flowInfo.targetDataFrameName[overflowPosition + index] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FlowUpdateStatus from './FlowUpdateStatus'
import FlowActionDropdown from './FlowActionDropdown'
import { useRouter } from '@/utils/composable/router'
import { defineComponent, ref, onMounted, computed } from '@vue/composition-api'
import { useMapGetters, useMapMutations } from '@/utils/composable/vuex'
import { useResizeObserver } from '@vueuse/core'

export default defineComponent({
  name: 'FlowCard',
  components: {
    FlowUpdateStatus,
    FlowActionDropdown
  },
  props: {
    flowInfo: {
      type: Object,
      default: null
    }
  },
  setup (props) {
    const router = useRouter()
    const {
      updateCurrentFlowInfo
    } = useMapMutations('modelFlowManagement', ['updateCurrentFlowInfo'])
    const {
      getCurrentAccountId: accountId,
      getCurrentGroupId: groupId
    } = useMapGetters('userManagement', ['getCurrentAccountId', 'getCurrentGroupId'])

    const occurOverflow = ref(false)
    const overflowPosition = ref(null)
    const isFlowUpdating = ref(false)

    const card = ref(null)
    const flowOutput = ref(null)
    const items = ref(null)

    const getFlowClasses = computed(() => {
      return ['flow-card', isFlowUpdating.value ? 'is-disabled' : null]
    })

    onMounted(() => {
      checkOutputLength()
    })

    useResizeObserver(card, (entries) => {
      checkOutputLength()
    })

    function goToFlowDetailPage (flow_id) {
      updateCurrentFlowInfo(props.flowInfo)
      router.push({
        name: 'FlowDetail',
        params: {
          account_id: accountId.value,
          group_id: groupId.value,
          flow_id
        }
      })
    }

    function goToDataSourcePage (index) {
      window.open(`/account/${accountId}/group/${groupId}/datasource/${props.flowInfo.value.targetDataSourceId[index]}/`, '_blank')
    }

    function checkOutputLength () {
      let sum = 0
      const containerLength = flowOutput.value.offsetWidth - 65
      if (!Array.isArray(items.value)) {
        occurOverflow.value = false
        overflowPosition.value = null
        return
      }
      items.value.forEach((item, index) => {
        sum += item.offsetWidth
        if (!occurOverflow.value && sum >= (containerLength + item.offsetWidth / 2)) {
          occurOverflow.value = true
          overflowPosition.value = index
        } else if (occurOverflow.value && sum < (containerLength + item.offsetWidth / 2)) {
          occurOverflow.value = false
          overflowPosition.value = null
        }
      })
    }

    return {
      card,
      flowOutput,
      items,
      occurOverflow,
      overflowPosition,
      isFlowUpdating,
      getFlowClasses,
      goToFlowDetailPage,
      goToDataSourcePage,
      checkOutputLength
    }
  }
})
</script>
<style lang="scss" scoped>
.flow-card {
  padding: 20px;
  border-radius: 5px;
  border: 2px solid #1C292B;
  background: #1C292B;
  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .flow-title {
      margin-right: 4px;
      font-size: 18px;
      color: #FFFFFF;
    }

    .flow-id {
      font-size: 14px;
      color: #DDDDDD;
    }

    .flow-record {
      flex: 1;
      text-align: right;
    }
  }

  &__list {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .tooltip-container {
      margin-left: 8px;
    }

    .tooltip {
      padding: 12px;
      width: 400px;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      white-space: normal;
      color: #DDDDDD;
    }
  }

  &.is-disabled {
    background: rgba(28, 41, 43, 0.2);
    [class^="flow"] {
      color: rgba(153, 153, 153, 0.4);
    }
    ::v-deep .update-status-block {
      [class^="flow"] {
        color: rgba(153, 153, 153, 0.9);
      }
    }
  }

  .flow-output {
    display: flex;
    width: 65%;
    overflow: hidden;
    &__result {
      display: flex;
    }
  }

  .flow-text {
    font-size: 12px;
    color: #999999;

    &--underline {
      text-decoration: underline;
      cursor: pointer;
      padding-right: 8px;
    }

    .spinner-block {
      padding: 0;
      margin-right: 8px;
    }
  }

  .flow-label {
    font-size: 12px;
    font-weight: 600;
    min-width: 65px;
    color: #999999;
  }

  .flow-info-wrapper {
    display: flex;
    margin-right: 12px;

    .flow-text {
      display: flex;
    }
  }

  .flow-text-wrapper {
    display: flex;
    align-items: center;
    flex: none;
    font-weight: 600;
    .icon {
      margin-right: 6px;
      font-size: 12px;
    }
  }

  .horizontal {
    &:first-of-type {
      padding-right: 10px;
    }

    &:not(:first-of-type) {
      border-left: 1px solid #999999;
      padding: 0 10px;
    }
  }

  .vertical {
    border-bottom: 1px solid #52696A;
    line-height: 30px;
  }

  .flow-function-wrapper {
    display: flex;
    margin-left: auto;
    .flow-function {
      &:not(:first-child) {
        padding-left: 20px;
        &::before {
          content: '';
          position: absolute;
          top: 4.5px;
          left: 8px;
          height: 16px;
          width: 1px;
          background-color: #9DBDBD;
        }
      }
    }
  }
}
</style>
