<template>
  <div
    v-if="flowUpdateStatus"
    class="update-status-block"
  >
    <div class="flow-label">
      {{ $t('modelFlow.updatedInformation') }}：
    </div>
    <div class="flow-text">
      <spinner
        v-if="isFlowUpdating"
        size="14"
      />
      <span
        v-if="flowUpdateTime"
        class="flow-update-time"
      >
        {{ flowUpdateTime }}
      </span>
      <span>
        {{ $t(`modelFlow.modelFlowUpdateStatus.${flowUpdateStatus.toLowerCase()}`) }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FlowUpdateStatus',
  props: {
    flowInfo: {
      type: Object,
      default: () => ({})
    },
    isFlowUpdating: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      highRankIndex: null
    }
  },
  computed: {
    flowUpdateStatus () {
      const highPriorityStatus = this.getHighPriorityStatus(this.flowInfo.targetDataFrameStatusType) || 'null'
      this.$emit('disable', ['Process', 'Pending'].includes(highPriorityStatus))
      return highPriorityStatus
    },
    flowUpdateTime () {
      switch (this.flowUpdateStatus) {
        case 'Enable':
        case 'Fail':
          return this.timeToDateTime(this.flowInfo.targetDataFrameUpdatedAt[this.highRankIndex])
        default:
          return null
      }
    }
  },
  methods: {
    // 狀態列表狀態可能不一致，依重要程度按等級，優先顯示列表裡等級高的狀態
    getHighPriorityStatus (statusList) {
      if (!Array.isArray(statusList)) return statusList
      const statusRank = { Process: 6, Pending: 5, Disable: 4, Fail: 3, Enable: 2, Temp: 1, null: 0 }
      let max = -1
      statusList.forEach((status, index) => {
        if (statusRank[status] > max) {
          this.highRankIndex = index
          max = statusRank[status]
        }
      })
      return Object.keys(statusRank)[6 - max]
    }
  }
}
</script>

<style lang="scss" scoped>
.update-status-block {
  display: flex;
  margin-right: 12px;
  .flow-label {
    font-size: 12px;
    font-weight: 600;
    color: #999999;
  }

  .flow-text {
    display: flex;
    font-size: 12px;
    color: #999999;

    .flow-update-time {
      margin-right: 8px;
    }
    .spinner-block {
      padding: 0;
      margin-right: 8px;
    }
  }
}
</style>
